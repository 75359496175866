import { Button, Form } from "react-bootstrap"
import Header from "../components/Header"
import { FormEvent, useEffect, useState } from "react"

const Home = () => {

    class apiType {
        name: string = "";
        apikey: string = "";
    }

    let secret: Array<apiType> = [] 
    
    const [name, setName] = useState<string>("")
    const [apikey, setApikey] = useState<string>("") 
    

    const saveApikey = (e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
       // const data = Object.fromEntries(formData.entries());

        //保存ずみのAPIKeyを配列へ
        const saveJson = localStorage.getItem("locoSecretArray")
        if (saveJson != null) {
            secret = JSON.parse(saveJson as string)
        }
        
        let tempApikey = formData.get("apikey") as string
        let tempName = formData.get("name") as string
        let temp: apiType = new apiType()
        if (tempApikey != null && tempName != null) {
            temp.apikey = tempApikey 
            temp.name = tempName
        } else {
            alert("NameまたはApikeyが入力されていません")
        }
        secret.push(temp)
        
        //const saveJson = localStorage.getItem("locoSecret")
        //secret.push(JSON.parse(saveJson as string))
        //console.log(data)

        localStorage.setItem("locoSecretArray", JSON.stringify(secret))
        /*
        const secretJson = localStorage.getItem("locoSecret")
        if (secretJson != null) {
            //secret = JSON.parse(secretJson as string)
            //setName(secret.name)
            //setApikey(secret.apikey)
        }
        */
        clearSelectApikey()
        setSelectApikey()
    }

    const deleteApikey = () => {

        const secretJson = localStorage.getItem("locoSecretArray")
        secret = JSON.parse(secretJson as string)

        if (secret.length == 1) {
            secret.splice(0,1)
        }
        //選択中のNameに一致するAPIKeyを削除
        secret.forEach((element, idx) => {
            if (element.apikey === apikey) {
                secret.splice(idx, 1)
            }
        })
        localStorage.setItem("locoSecretArray", JSON.stringify(secret))

        //実際に検索で使用するAPIkeyがリストから削除された物と同じなら削除を行う
        const locoApikey = localStorage.getItem("locoApikey")
        if (locoApikey != null) {
            if (locoApikey === apikey) {
                localStorage.removeItem("locoApikey")
            }
        }
    }

    const editApikey = () => {
        const secretJson = localStorage.getItem("locoSecretArray")
        secret = JSON.parse(secretJson as string)

        //選択中のNameに一致するAPIKeyを編集
        secret.forEach((element, idx) => {
            if (element.apikey === apikey) {
                const selectApiKey = document.getElementById("listApikey") as HTMLSelectElement;
                secret[idx].apikey = selectApiKey.value 
                const selectApiName = document.getElementById("name") as HTMLSelectElement;
                secret[idx].name = selectApiName.value
                localStorage.setItem("locoSecretArray", JSON.stringify(secret))
            }
        })
    }

    useEffect(() => {
        setSelectApikey()
    },[])

    const clearSelectApikey = () => {
        const selectApiName = document.getElementById("selectName") as HTMLSelectElement;
        let maxOptionLength = selectApiName.options.length;
        for ( let idx = maxOptionLength ; idx >= 0 ; idx-- ) {
          console.log("maxoption:" + maxOptionLength)
          selectApiName.options.remove(idx)
        }
      }
    

    const setSelectApikey = () => {
        const secretJson = localStorage.getItem("locoSecretArray")
        if (secretJson != null) {
           
            secret = JSON.parse(secretJson as string)

            const selectApiName = document.getElementById("selectName") as HTMLSelectElement;
            const option1 = document.createElement("option");
            option1.value = "";
            option1.textContent = "名前を選択して下さい";
            selectApiName?.appendChild(option1)
            
            secret.forEach((element, idx) => {
                const option1 = document.createElement("option");
                option1.value = element.apikey;
                option1.textContent = element.name;
                selectApiName?.appendChild(option1)
            })
            
        } else {
            const selectApiName = document.getElementById("selectName") as HTMLSelectElement;
            const option1 = document.createElement("option");
            option1.value = "";
            option1.textContent = "名前を選択して下さい";
            selectApiName?.appendChild(option1)
        }
    }

    const selectApiName = () => {
        const selectName = document.getElementById("selectName") as HTMLSelectElement;
        let idx = selectName.selectedIndex;
        console.log(selectName.options[idx].value);
        console.log(selectName.options[idx].text);

        const selectApiKey = document.getElementById("listApikey") as HTMLSelectElement;
        selectApiKey.value = selectName.options[idx].value
        const selectApiName = document.getElementById("name") as HTMLSelectElement;
        selectApiName.value = selectName.options[idx].text
        setApikey(selectName.options[idx].value)
        console.log(selectName.options[idx].value)
        console.log(secret)
    }

    return (
        <div>
            <Header current_page="Home"></Header>
            <div className="card mt-3">
                <Form className="col-md-7" onSubmit={(e) => saveApikey(e)}>
                    <Form.Label className="font-weight-bold">APIKeyの登録</Form.Label>
                    <br/>
                    <Form.Label>※保存したいAPIKeyと名前を入力して下さい</Form.Label>
                    <Form.Control type="text" className="w-27 mb-2 mx-3" name="name" placeholder="名前を入力"></Form.Control>
                    <Form.Control type="text" className="w-27 mx-3" name="apikey" placeholder="APIKeyを入力"></Form.Control>
                    <Form.Group as={Button} type="submit" className="mt-3 mb-2 mx-3">保存</Form.Group>
                </Form>
            </div>
            <div className="card mt-3"> 
                <Form className="col-md-7">
                    <Form.Label className="font-weight-bold">登録中のAPIKeyリスト</Form.Label>
                    <p>選択したAPIKeyがAPIKey詳細に表示されます</p>
                    <Form.Control as="select"  className="w-27 mb-2 mx-3" id="selectName" onChange={e => selectApiName()}></Form.Control>
                </Form>
                
               
            </div>
            <div className="card mt-3">
                <Form className="col-md-7">
                    <p></p>
                    <Form.Label className="font-weight-bold">APIKey詳細</Form.Label>
                    
                    <p>※編集する場合は選択したAPIKeyの値を変更し、編集を反映ボタンを押して下さい。</p>
                    <Form.Label className="font-weight-bold">名前</Form.Label>
                    <Form.Control type="text"  className="w-27 mb-2 mx-3" id="name"></Form.Control>
                    <Form.Label className="font-weight-bold">APIKey</Form.Label>
                    <Form.Control type="text"  className="w-27 mx-3" id="listApikey"></Form.Control>
                    <Form.Group as={Button} type="submit" className="mt-3 mb-2 mx-3" onClick={() => deleteApikey()}>選択したAPIKeyを削除</Form.Group>
                    <Form.Group as={Button} type="submit" className="mt-3 mb-2 mx-3" onClick={() => editApikey()}>編集を反映</Form.Group>
                </Form>
            </div>
       </div>


    )
}

export default Home