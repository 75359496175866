import React from "react";
import { Dimmer , Loader, Segment } from "semantic-ui-react";

function Loading({ inverted = true, content="CSV作成処理中..."}) {
return (
    
    < Dimmer inverted={inverted} active={true} >
        < Loader size="massive" content={content}/>
    </ Dimmer>
    
)
}

export default Loading;