import { flexRender,
    getCoreRowModel,
    useReactTable, } from '@tanstack/react-table';
import beaconResultType from '../model/beaconResultType';
import BeaconModel from '../model/BeaconModel';
import { useState } from 'react';

type propsType = {
    beaconData: BeaconModel[]
    beaconlogs: beaconResultType[]
}

const BeaconLogResult = (props: propsType) => {
    const data: beaconResultType[] = props.beaconlogs;
    const beaconsArray: BeaconModel[] = props.beaconData;
    //const [columnVisibility, setColumnVisibility] = useState({})

    data.forEach((value: beaconResultType, index:number) => {
        beaconsArray.forEach((beaconValue: BeaconModel) => {
            if (value.beacon_id == beaconValue.id) {
                data[index].name = beaconValue.name
            }
        })
    });

    const columns = [
        { header: "no", accessorKey: "no" },
        { header: "id", accessorKey: "id" },
        { header: "bundle_id", accessorKey: "bundle_id" },
        { header: "device_id", accessorKey: "device_id" },
        { header: "beacon_id", accessorKey: "beacon_id" },
        { header: "name", accessorKey: "name" },
        { header: "rssi", accessorKey: "rssi" },
        { header: "detected", accessorKey: "detected" },
        { header: "arrived", accessorKey: "arrived" },
        { header: "latitude", accessorKey: "latitude" },
        { header: "longitude", accessorKey: "longitude" },
        { header: "abs_height", accessorKey: "abs_height" }
        
      ];
      
    const table = useReactTable({columns, data, getCoreRowModel: getCoreRowModel()})
    return (
        <div>
            <p className='mx-3'>項目の表示・非表示</p>
            <div id='colVisible' className="px-1 mx-3">
                {table.getAllLeafColumns().map(column => {
                return (
                <label className='mx-3'>
                    <input
                    {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                    }}
                    />{' '}
                    {column.id}
                </label>
                )
                })}
            </div>

            <div className='dataScroll'>
                <table>
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default BeaconLogResult