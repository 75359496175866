import { flexRender,
    getCoreRowModel,
    useReactTable, } from '@tanstack/react-table';
import deviceResultType from '../model/deviceResultType';

const DeviceLogResult = ({result}:{result:deviceResultType[]}) => {
    const data: deviceResultType[] = result;
    const columns = [
        { header: "no", accessorKey: "no" },
        { header: "id", accessorKey: "id" },
        { header: "bundle_id", accessorKey: "bundle_id" },
        { header: "device_id", accessorKey: "device_id" },
        { header: "os_type", accessorKey: "os_type" },
        { header: "os_version", accessorKey: "os_version" },
        { header: "sdk_version", accessorKey: "sdk_version" },
        { header: "model", accessorKey: "model" },
        { header: "bluetooth", accessorKey: "bluetooth" },
        { header: "location", accessorKey: "location" },
        { header: "metadata", accessorKey: "metadata" },
        { header: "detected", accessorKey: "detected" },
        { header: "arrived", accessorKey: "arrived" }
        
      ];
      
    const table = useReactTable({columns, data, getCoreRowModel: getCoreRowModel()})
    return (
        <div>
            <p className='mx-3'>項目の表示・非表示</p>
            <div id='colVisible' className="px-1 mx-3">
                {table.getAllLeafColumns().map(column => {
                return (
                <label className='mx-3'>
                    <input
                    {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                    }}
                    />{' '}
                    {column.id}
                </label>
                )
                })}
            </div>
            <div className='dataScroll'>
                <table>
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DeviceLogResult