import { Column, VisibilityState, flexRender,
    getCoreRowModel,
    useReactTable, } from '@tanstack/react-table';
import clusterMasterResultType from '../model/ClusterMasterResultType';
import { useEffect, useState } from 'react';

const ClusterMasterResult = ({result}:{result:clusterMasterResultType[]}) => {
    const data: clusterMasterResultType[] = result;
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

    const columns = [
        { header: "no", accessorKey: "no" },
        { header: "id", accessorKey: "id" },
        { header: "name", accessorKey: "name" },
        { header: "tag", accessorKey: "tag" },
        { header: "image", accessorKey: "image" },
        { header: "cm_per_pixel", accessorKey: "cm_per_pixel" },
        { header: "att_coef", accessorKey: "att_coef" },
        { header: "parent_id", accessorKey: "parent_id" },
        { header: "beacons", accessorKey: "beacons" },
        { header: "enabled", accessorKey: "enabled" },
        { header: "created", accessorKey: "created" },
        { header: "updated", accessorKey: "updated" }
       
        
      ];
      
    const table = useReactTable({columns, data, state: {
        columnVisibility: columnVisibility,
      },
      getCoreRowModel: getCoreRowModel(),
      onColumnVisibilityChange: setColumnVisibility,
    })

    return (
        <div>
            <p className='mx-3'>項目の表示・非表示</p>
            <div id='colVisible' className="px-1 mx-3">
                {table.getAllLeafColumns().map(column => {
                return (
                <label className='mx-3'>

                    <input
                        checked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        type="checkbox"
                    />{' '}
                    {column.id}
                </label>
                )
                })}
            </div>
            <div className='dataScroll'>
                <table>
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ClusterMasterResult