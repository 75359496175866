import { useState,useEffect } from "react";
import { Button, Nav, Navbar } from "react-bootstrap"




const Header = ({current_page}: {current_page: string}) => {
  const  [buttonCaption, setButtonCaption] = useState<string>("");

  useEffect(() => {
    const locoenv = localStorage.getItem("locoEnv");
    if (locoenv == null) {
      localStorage.setItem("locoEnv", "prod");
    }
    setButtonCaption(locoenv ? locoenv : "prod")
  }, []) 

  const setApiEnv = () => {
    switch(localStorage.getItem("locoEnv")) {
      case "prod":
        localStorage.setItem("locoEnv", "stg");
        setButtonCaption("stg")
        break;
      case "stg":
        localStorage.setItem("locoEnv", "prod");
        setButtonCaption("prod")
        break;
      default:
        localStorage.setItem("locoEnv", "prod");
        setButtonCaption("prod")
        break;
    }
  }
  return (
    
    <Navbar expand="lg" bg="dark" variant='dark'>
      <Navbar.Brand className="font-weight-bold" href="/home">Loco LogBrowser</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <Nav className='mr-auto'>
        {current_page === "Home" ? <Nav.Item><Nav.Link href='/Home' className="active">Home</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/Home'>Home</Nav.Link></Nav.Item>}
        {current_page === "Beacon" ? <Nav.Item><Nav.Link href='/BeaconLog' className="active">BeaconLog</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/BeaconLog'>BeaconLog</Nav.Link></Nav.Item>}
        {current_page === "Region" ? <Nav.Item><Nav.Link href='/Regionlog' className="active">RegionLog</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/Regionlog'>RegionLog</Nav.Link></Nav.Item>}
        {current_page === "Device" ? <Nav.Item><Nav.Link href='/DeviceLog' className="active">DeviceLog</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/DeviceLog'>DeviceLog</Nav.Link></Nav.Item>}
        {current_page === "Event" ? <Nav.Item><Nav.Link href='/EventLog' className="active">EventLog</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/EventLog'>EventLog</Nav.Link></Nav.Item>}
        {current_page === "Battery" ? <Nav.Item><Nav.Link href='/BatteryLog' className="active">BatteryLog</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/BatteryLog'>BatteryLog</Nav.Link></Nav.Item>}
        {current_page === "Position" ? <Nav.Item><Nav.Link href='/PositionLog' className="active">PositionLog</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/PositionLog'>PositionLog</Nav.Link></Nav.Item>}
        {current_page === "BeaconMaster" ? <Nav.Item><Nav.Link href='/BeaconMaster' className="active">BeaconMaster</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/BeaconMaster'>BeaconMaster</Nav.Link></Nav.Item>}
        {current_page === "ClusterMaster" ? <Nav.Item><Nav.Link href='/ClusterMaster' className="active">ClusterMaster</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/ClusterMaster'>ClusterMaster</Nav.Link></Nav.Item>}
        {current_page === "RegionMaster" ? <Nav.Item><Nav.Link href='/RegionMaster' className="active">RegionMaster</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/RegionMaster'>RegionMaster</Nav.Link></Nav.Item>}
        {current_page === "ActionMaster" ? <Nav.Item><Nav.Link href='/ActionMaster' className="active">ActionMaster</Nav.Link></Nav.Item> : <Nav.Item><Nav.Link href='/ActionMaster'>ActionMaster</Nav.Link></Nav.Item>}
       
      </Nav>
      <Navbar.Text className="mx-2">Ver1.5</Navbar.Text>
      <Button type="button" className="mb-1" title="StagingとProductionを切り替えられます" onClick={() => setApiEnv()}>{buttonCaption === "prod" ? "Production" : "Staging"}</Button>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header