import { flexRender,
    getCoreRowModel,
    useReactTable, } from '@tanstack/react-table';
import positionResultType from '../model/positionResultType';

const PositionLogResult = (props:{result:positionResultType[], mounted: string}) => {
    const data: positionResultType[] = props.result;
    let columns;
    if (props.mounted === "beacon") {
         columns = [
            { header: "no", accessorKey: "no" },
            { header: "cluster_id", accessorKey: "cluster_id" },
            { header: "device_id", accessorKey: "device_id" },
            { header: "nearest", accessorKey: "nearest" },
            { header: "x", accessorKey: "x" },
            { header: "y", accessorKey: "y" },
            { header: "h", accessorKey: "h" },
            { header: "r", accessorKey: "r" },
            { header: "detected", accessorKey: "detected" }
            
          ];
    } else {
        columns = [
            { header: "no", accessorKey: "no" },
            { header: "cluster_id", accessorKey: "cluster_id" },
            { header: "beacon_id", accessorKey: "beacon_id" },
            { header: "nearest", accessorKey: "nearest" },
            { header: "x", accessorKey: "x" },
            { header: "y", accessorKey: "y" },
            { header: "h", accessorKey: "h" },
            { header: "r", accessorKey: "r" },
            { header: "detected", accessorKey: "detected" }
            
          ];
    }
   
      
    const table = useReactTable({columns, data, getCoreRowModel: getCoreRowModel()})
    return (
        <div>
            <p className='mx-3'>項目の表示・非表示</p>
            <div id='colVisible' className="px-1 mx-3">
                {table.getAllLeafColumns().map(column => {
                return (
                <label className='mx-3'>
                    <input
                    {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                    }}
                    />{' '}
                    {column.id}
                </label>
                )
                })}
            </div>
            <div className='dataScroll'>
                <table>
                    <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PositionLogResult