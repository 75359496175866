import axios, { AxiosHeaderValue } from "axios";
import { useEffect, useState } from "react";
import beaconResultType from "../model/beaconResultType";
import { Button, Col, Form, Row } from "react-bootstrap";
import Result from "../components/BeaconLogResult";
import Header from "../components/Header";
import PageSelect from "../components/PageSelect";
import BeaconModel from "../model/BeaconModel";
import ExcelJS from 'exceljs';
import Loading from "../components/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox } from "semantic-ui-react";

const BeaconLog = () => {

    const [apiKey, setApikey] = useState<string>("");
    const [deviceid, setDeviceId] = useState<string>("");
    const [per_page, setPer_Page] = useState<string>("");
    const [beacon_id, setBeacon_Id] = useState<string>("");
    const [rssi_gt, setRssi_gt] = useState<string>("");
    const [rssi_lt, setRssi_lt] = useState<string>("");
    const [detected_gt, setDetcted_gt] = useState<string>("");
    const [detected_lt, setDetcted_lt] = useState<string>("");
    const [arrived_gt, setArrived_gt] = useState<string>("");
    const [arrived_lt, setArrived_lt] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [pageNo, setPageNo] = useState<number>(0);
    const [lastPageNo, setLastPageNo] = useState<number>(0);
    const [total, setTotal] = useState<number>(-1);
    const [result, setResult] = useState<beaconResultType[]>([]);
    const results: beaconResultType[] = [];
    const [csvResult, setCSVResult] = useState<beaconResultType[]>([]);
    const [beaconResult, setBeaconResult] = useState<BeaconModel[]>([]);
    const beaconResults: BeaconModel[] = [];

    const [isNoCheck, setNoCheck] = useState<boolean>(false);
    const [isNamaCheck, setNameCheck] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    var apiUrl:string = "";
    let beaconApiUrl = "https://api.bc-loco.jp/api/beacons"

    

    const checkLocoEnv = () => {
      if (localStorage.getItem("locoEnv") === "prod") {
        apiUrl = "https://api.bc-loco.jp/api/beacon_logs"
        beaconApiUrl = "https://api.bc-loco.jp/api/beacons"
      } else {
        apiUrl = "http://stg.api.bc-loco.jp/api/beacon_logs"
        beaconApiUrl = "http://stg.api.bc-loco.jp/api/beacons"
      }
    }
    
    const getBeaconLog = async(e: any, pageNo: number) => {
        e.preventDefault();
        saveParams();
        saveApikey();
        checkLocoEnv();
        await axios.get(apiUrl,
        {
          headers: {
              "Authorization": apiKey,
              "Content-Type": "application/json"
          },
          params: {
              ...(deviceid ? {device_id: [deviceid]} : {}),
              ...(pageNo ? {page: pageNo} : {}),
              ...(per_page ? {per_page: per_page} : {}),
              ...(beacon_id ? {beacon_id: beacon_id} : {}),
              ...(rssi_gt ? {rssi_gt: rssi_gt} : {}),
              ...(rssi_lt ? {rssi_lt: rssi_lt} : {}),
              ...(detected_gt ? {detected_gt: detected_gt.replace("T", " ")} : {}),
              ...(detected_lt ? {detected_lt: detected_lt.replace("T", " ")} : {}),
              ...(arrived_gt ? {arrived_gt: arrived_gt.replace("T", " ")} : {}),
              ...(arrived_lt ? {arrived_lt: arrived_lt.replace("T", " ")} : {}),
              ...(sort ? {sort: sort} : {})
          }
        })
        .then(async res => {
          console.log(res);
          if(res.data.code === 400) {
            alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
            return 
          } else if(res.data.code === 401) {
            alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
            return 
          } 
          const items = JSON.parse(JSON.stringify(res.data.payload))
            if (items.total > 100000) {
              alert("10万件を超えるデータは表示出来ません。 総件数:" + items.total + "\n日付範囲を調整して下さい。")
              return 
            }
            setTotal(items.total)
            setPageNo(items.current_page)
            setLastPageNo(items.last_page)
            
            items.data.forEach((value: beaconResultType, index: number) => {
              results.push(value)
              results[index].no = items.from;
              items.from++;
            })
            setResult(results)
            getBeacons();
        })
        .catch(err => 
          alert("エラーが発生しました。" + err)

        )
    }

    const getBeacons = async() => {
      //e.preventDefault();
      await axios.get(beaconApiUrl,
      {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        },
        params: {
          ...({per_page: 1000})
        }
      })
      .then(async res => {
        console.log(res);
        if(res.data.code === 400) {
          alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
          return
        } else if(res.data.code === 401) {
          alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
          return
        }
        const items = JSON.parse(JSON.stringify(res.data.payload))
        console.log(items)

        items.data.forEach((value: BeaconModel) => {
          beaconResults.push(value)
        })

        setBeaconResult(beaconResults)
        
      })
      .catch(err => 
        alert("エラーが発生しました。" + err)
      )
    }






    const createCSV = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
     
      
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("beaconLog");
      const worksheet = workbook.getWorksheet("beaconLog");

      const columns: Partial<ExcelJS.Column>[] = [];

      if (isNoCheck) {
        columns.push({ header: "no", key: "no" }) 
      }
      columns.push({ header: "id", key: "id" })
      columns.push({ header: "bundle_id", key: "bundle_id" })
      columns.push({ header: "device_id", key: "device_id" })
      columns.push({ header: "beacon_id", key: "beacon_id" })
      if (isNamaCheck) {
        columns.push({ header: "name", key: "name" })
      }
      columns.push({ header: "rssi", key: "rssi" })
      columns.push({ header: "detected", key: "detected" })
      columns.push({ header: "arrived", key: "arrived" })
      columns.push({ header: "latitude", key: "latitude" })
      columns.push({ header: "longitude", key: "longitude" })
      columns.push({ header: "abs_height", key: "abs_height" })
      
      /*
      worksheet.columns = [
        { header: "no", key: "no" }
      ]

      worksheet.columns =[
        { header: "id", key: "id" },
        { header: "bundle_id", key: "bundle_id" }
      ]

      worksheet.columns = [
        { header: "device_id", key: "device_id" },
        { header: "beacon_id", key: "beacon_id" },
        { header: "name", key: "name" },
        { header: "rssi", key: "rssi" },
        { header: "detected", key: "detected" },
        { header: "arrived", key: "arrived" },
        { header: "latitude", key: "latitude" },
        { header: "longitude", key: "longitude" },
        { header: "abs_height", key: "abs_height" }
        
      ]
      */
     worksheet.columns = columns;

      setIsLoading(true);
      if (await getAllBeaconLog(e, 1) == true ) {
        csvResult.forEach((value: beaconResultType) => {
          beaconResult.forEach((beaconValue: BeaconModel) => {
            if (value.beacon_id == beaconValue.id) {
              value.name  = beaconValue.name;
            }
          })
          worksheet.addRow(value);
        });

        csvResult.length = 0;

        console.log("Row Count:" + worksheet.rowCount)
        setIsLoading(false);

        try {
          // ファイル保存ダイアログを表示して FileSystemFileHandle オブジェクトを取得
          //const fh = await (window as any).showSaveFilePicker({ suggestedName: "beaconlog_" + getNowDate() +  ".csv" });
              
          // FileSystemWritableFileStream オブジェクトを取得
          //const stream = await fh.createWritable();
          const uint8Array = await workbook.csv.writeBuffer();
          const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");

          
          setTimeout(()=> {
            a.href = url;
            a.download = "beaconlog_" + getNowDate() +  ".csv"; //フォーマットによってファイル拡張子を変えている
            a.click();
            a.remove();

           
          },1000)
          

          // テキストデータをファイルに書き込む
          //await stream.write(blob);

          // ファイルを閉じる
          //await stream.close();
        } catch (error) {
          alert(error);
        }
      } else {
        setIsLoading(false);
      }
     
    }

    const getAllBeaconLog = async(e: any, pageNo: number) => {
      let ret = true;
      e.preventDefault();
      checkLocoEnv();
      await axios.get(apiUrl,
      {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        },
        params: {
            ...(deviceid ? {device_id: [deviceid]} : {}),
            ...({per_page: 1000}),
            ...(pageNo ? {page: pageNo} : {}),
            ...(beacon_id ? {beacon_id: beacon_id} : {}),
            ...(rssi_gt ? {rssi_gt: rssi_gt} : {}),
            ...(rssi_lt ? {rssi_lt: rssi_lt} : {}),
            ...(detected_gt ? {detected_gt: detected_gt.replace("T", " ")} : {}),
            ...(detected_lt ? {detected_lt: detected_lt.replace("T", " ")} : {}),
            ...(arrived_gt ? {arrived_gt: arrived_gt.replace("T", " ")} : {}),
            ...(arrived_lt ? {arrived_lt: arrived_lt.replace("T", " ")} : {}),
            ...(sort ? {sort: sort} : {})
        }
      })
      .then(async res => {
        console.log(res);
        if(res.data.code === 400) {
          alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
          return ret = false
        } else if(res.data.code === 401) {
          alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
          return ret = false
        }
        const items = JSON.parse(JSON.stringify(res.data.payload))
        if (items.total > 100000) {
          alert("10万件を超えるデータは出力出来ません。 総件数:" + items.total + "\n日付範囲を調整して下さい。")
          return ret = false
        } else {
          let csvArrayCount = csvResult.length;
          items.data.forEach((value: beaconResultType, index: number) => {
            csvResult.push(value);
            csvResult[index + csvArrayCount].no = items.from;

            items.from++;
          })
          
          setCSVResult(csvResult);
          if (items.current_page != items.last_page) {
              await getAllBeaconLog(e, items.current_page + 1);
          } else {
            console.log("getall: " + csvResult.length);
            getBeacons();
          }
        }
      })
      .catch(err => 
        alert("エラーが発生しました。" + err)
      )

      return ret
  }

  const changeDetectedGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setDetcted_gt(value);
  }

  const changeDetectedLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setDetcted_lt(value);
  }

  const changeArrivedGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setArrived_gt(value);
  }

  const changeArrivedLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setArrived_lt(value);
  }

  const getNowDate = () => {
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + (dt.getDate())).slice(-2);
    let result = y  + m +  d;
    return result;
  }

  type beaconParamsType = {
    apikey?: string,
    deviceid?: string,
    perpage?: string,
    beaconid?: string,
    rssigt?: string,
    rssilt?: string,
    detectedgt?: string,
    detectedlt?: string,
    arrivedgt?: string,
    arrivedlt?: string,
    sort?: string
  }

  const saveParams = () => {
    let params: beaconParamsType = {};
    //params.apikey = apiKey ? apiKey : "";
    params.deviceid = deviceid ? deviceid : "";
    params.perpage = per_page ? per_page : "";
    params.beaconid = beacon_id ? beacon_id : "";
    params.rssigt = rssi_gt ? rssi_gt : "";
    params.rssilt = rssi_lt ? rssi_lt : "";
    params.detectedgt = detected_gt ? detected_gt : "";
    params.detectedlt = detected_lt ? detected_lt : "";
    params.arrivedgt = arrived_gt ? arrived_gt : "";
    params.arrivedlt = arrived_lt ? arrived_lt : "";
    params.sort = sort ? sort : "";

    let json = JSON.stringify(params);
    console.log("params:" + json);
    localStorage.setItem("locoBeaconParams", json)

  }

  const saveApikey = () => {
    localStorage.setItem("locoApikey", apiKey ? apiKey : "" );
  }

  const loadParams = () => {
    const paramsJson = localStorage.getItem("locoBeaconParams")
    if (paramsJson != null) {
      const params:beaconParamsType = JSON.parse(paramsJson as string)
      //setApikey(params.apikey ? params.apikey : "");
      setDeviceId(params.deviceid ? params.deviceid : "");
      setPer_Page(params.perpage ? params.perpage : "")
      setBeacon_Id(params.beaconid ? params.beaconid : "");
      setRssi_gt(params.rssigt ? params.rssigt : "");
      setRssi_lt(params.rssilt ? params.rssilt : "");
      setDetcted_gt(params.detectedgt ? params.detectedgt : "");
      setDetcted_lt(params.detectedlt ? params.detectedlt : "");
      setArrived_gt(params.arrivedgt ? params.arrivedgt : "");
      setArrived_lt(params.arrivedlt ? params.arrivedlt : "");
      setSort(params.sort ? params.sort : "");

      
    }
  }

  const loadApikey = () => {
    const locoApikey = localStorage.getItem("locoApikey")
    if (locoApikey != null) {
      setApikey(locoApikey ? locoApikey : "");
    }
  }

  const setSelectApikey = () => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
        const secret = JSON.parse(secretJson as string)

        const selectApikey = document.getElementById("apikey") as HTMLSelectElement;
        const option1 = document.createElement("option");
        option1.value = "";
        option1.textContent = "APIKeyを選択して下さい";
        selectApikey?.appendChild(option1)

        secret.forEach((element: { apikey: string; name: string | null; }) => {
            const option1 = document.createElement("option");
            option1.value = element.apikey;
            option1.textContent = element.name;
            selectApikey?.appendChild(option1)
        })
        
        //setName(secret.name)
        //setApikey(secret.apikey)
    }
}

  useEffect(() => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      setSelectApikey()
    }
    loadParams();
    loadApikey();
    
  },[])

  return (
    <div>
        <Header current_page="Beacon"/>
      
      
      <div className="card">
          <Form className="font-weight-bold" onSubmit={(e) => getBeaconLog(e, 0)}>
              <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>ApiKey</Form.Label>
                <Form.Control as="select" id="apikey" value={apiKey as string} placeholder="ApiKeyを選択" onChange={(e) => setApikey(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Device_Id</Form.Label>
                <Form.Control type="text" name="deviceId"  value={deviceid as string} placeholder="Device_Idを入力" onChange={(e) => setDeviceId(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Per_Page（デフォルト100件/最大1000件）</Form.Label>
                <Form.Control type="text" name="per_page" value={per_page as string} placeholder="１ページ辺りの最大件数を指定" onChange={(e) => setPer_Page(e.target.value)} />
              </Col>
              </Form.Group>
              
            
            
              <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>Beacon_Id</Form.Label>
                <Form.Control type="text" name="beacon_id" value={beacon_id as string} className="w-27" placeholder="Beacon_Idを入力" onChange={(e) => setBeacon_Id(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Rssi_gt（RSSIの値以上を検索）</Form.Label>
                <Form.Control type="text" name="rssi_gt" value={rssi_gt as string} className="w-27" placeholder="RSSIの値（>=）を入力" onChange={(e) => setRssi_gt(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Rssi_lt（RSSIの値以下を検索）</Form.Label>
                <Form.Control type="text" name="rssi_lt" value={rssi_lt as string} className="w-27" placeholder="RSSIの値（<=）を入力" onChange={(e) => setRssi_lt(e.target.value)} />
              </Col>
              </Form.Group>
              <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>Detected_gt（ビーコン発見時刻以降）</Form.Label>
                <Form.Control type="datetime-local" step="1" autoComplete="on" name="detected_gt" value={detected_gt as string} className="w-27" placeholder="ビーコン発見時の時刻（>=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeDetectedGt(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Detected_lt（ビーコン発見時刻以前）</Form.Label>
                <Form.Control type="datetime-local" step="1" name="detected_lt" value={detected_lt as string} className="w-27" placeholder="ビーコン発見時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeDetectedLt(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Arrived_gt（ログのサーバー到達時刻以降）</Form.Label>
                <Form.Control type="datetime-local" step="1" name="arrived_gt" value={arrived_gt as string} className="w-27" placeholder="ビーコンログ到達時の時刻（>=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeArrivedGt(e.target.value)} />
              </Col>
              </Form.Group>
              <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>Arrived_lt（ログのサーバー到達時刻以前）</Form.Label>
                <Form.Control type="datetime-local" step="1" name="arrived_lt" value={arrived_lt as string} className="w-27" placeholder="ビーコンログ到達時の時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeArrivedLt(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Sort（デフォルト: DESC（新着順））</Form.Label>     
                <Form.Control as="select" className="w-27" value={sort as string} onChange={e => setSort(e.target.value)} >
                    <option value="">ASC（古い順）またはDESC（新着順）を指定</option>
                    <option value="asc">ASC（古い順）</option>
                    <option value="desc">DESC（新着順）</option>
                </Form.Control>
              </Col>
              </Form.Group>
            <div className="text-center">
              <Form.Group as={Button} type="submit" className="mt-3 mb-2">View BeaconLogs</Form.Group>
            </div>
          </Form>
          <Form className="font-weight-bold">
            <div className="text-center">
              <Form.Group as={Button} type="button" id="csvbtn" className="mt-3 mb-2" onClick={(e) =>  createCSV(e)}>CSV Export</Form.Group>
              <div>
                  <Form.Label>CSV出力の項目に含める</Form.Label> 
                  <label>
                  <input type="checkbox" className='mx-2' onChange={() => setNoCheck(prevState => !prevState)}></input>
                  no</label>
                  <label>
                  <input type="checkbox" className='mx-2' onChange={() => setNameCheck(prevState => !prevState)}></input>
                  name</label>
              </div>
            </div>
          </Form>
      </div>
      {(() => {
        if (isLoading) {
          return (
            <div>
              <Loading/>
            </div>
          )
        }
      
      })()}
  
      {(() => {
        if (beaconResult.length > 0 && total >= 0) {
            return  (
              <div>
                <p className="mx-4">TotalLogCount:{total}</p>
                <div className="d-flex justify-content-around">     
                  <PageSelect pageNo={pageNo} lastPageNo={lastPageNo} getLog={getBeaconLog}></PageSelect>  
                </div>  
                <Result beaconlogs={result} beaconData={beaconResult}></Result>
              </div>
            );
        } else {
          return (
            <div>
                {
                    total === 0 && <p className="mx-4">TotalLogCount:{total}</p> 
                }
            </div>
            );
        }
      })()}
    </div>
  )
}

export default BeaconLog