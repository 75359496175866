import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BeaconLog from './Pages/BeaconLog';
import Home from './Pages/Home';
import RegionLog from './Pages/RegionLog';
import DeviceLog from './Pages/DeviceLog';
import EventLog from './Pages/EventLog';
import BatteryLog from './Pages/BatteryLog';
import ClusterMaster from './Pages/ClusterMaster';
import BeaconMaster from './Pages/BeaconMaster';
import RegionMaster from './Pages/RegionMaster';
import ActionMaster from './Pages/ActionMaster';
import PositionLog from './Pages/PositionLog';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/BeaconLog" element={<BeaconLog /> } />
        <Route path="/RegionLog" element={<RegionLog /> } />
        <Route path="/DeviceLog" element={<DeviceLog /> } />
        <Route path="/EventLog" element={<EventLog /> } />
        <Route path="/BatteryLog" element={<BatteryLog /> } />
        <Route path="/PositionLog" element={<PositionLog /> } />
        <Route path="/ClusterMaster" element={<ClusterMaster /> } />
        <Route path="/BeaconMaster" element={<BeaconMaster /> } />
        <Route path="/RegionMaster" element={<RegionMaster /> } />
        <Route path="/ActionMaster" element={<ActionMaster /> } />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
