import {Pagination} from 'react-bootstrap';

type PagePropsType = {
    pageNo: number;
    lastPageNo: number;
    getLog: (e: any, pageNo: number) => void;
}
const PageSelect = (props: PagePropsType) => {

  return (
    
    <Pagination>
      <Pagination.Item onClick={(e) => props.getLog(e, 1)} >&laquo;</Pagination.Item>
      <Pagination.Item onClick={(e) => props.getLog(e,props.pageNo - 1 < 1 ? 1 : props.pageNo - 1)}>&lsaquo;</Pagination.Item>
      { props.pageNo - 2 >= 1 && <Pagination.Item onClick={(e) => props.getLog(e,props.pageNo - 2)}>{props.pageNo - 2}</Pagination.Item> }
      { props.pageNo - 1 >= 1 && <Pagination.Item onClick={(e) => props.getLog(e,props.pageNo - 1)}>{props.pageNo - 1}</Pagination.Item> }
      <Pagination.Item active={true} activeLabel=''>{props.pageNo}</Pagination.Item>
      { props.pageNo + 1 <= props.lastPageNo && <Pagination.Item onClick={(e) => props.getLog(e,props.pageNo + 1)}>{props.pageNo + 1}</Pagination.Item> }
      { props.pageNo + 2 <= props.lastPageNo && <Pagination.Item onClick={(e) => props.getLog(e,props.pageNo + 2)}>{props.pageNo + 2}</Pagination.Item> }
      <Pagination.Item onClick={(e) => props.getLog(e,props.pageNo + 1 > props.lastPageNo ? props.lastPageNo : props.pageNo + 1)}>&rsaquo;</Pagination.Item>
      <Pagination.Item onClick={(e) => props.getLog(e, props.lastPageNo)}>&raquo;</Pagination.Item>
    </Pagination>
  );
}

export default PageSelect;